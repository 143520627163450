.notFoundContainer{
    width: 100%;
    margin-top: 200px;
    
}

.error404{
    width: 50%;
}

.btnNot {
    background-color: var(--main-color);
}

.btnNot:hover {
    background-color: rgb(4, 4, 83);
    
}

@media only screen and (min-width: 601px) {
    .error404{
        width: 50%;
    }

    .btnNot {
        background-color: var(--main-color);
        padding-inline: 90px ;
        margin-top: -40px;
    }
}

@media only screen and (max-width: 600px) {
    .error404{
        width: 95%;
    }

    .btnNot {
        background-color: var(--main-color);
        padding-inline: 50px ;
        margin-top: 0px;
    }
}