.nav {
  background-color: #173459f3;
  color: var(--container-color) !important;
}

.linkStyle:focus,
.linkStyle:hover {
  color: #b48b0f !important;
  transform: scale(98%);
}

.linkDropStyle:focus, 
.linkDropStyle:hover {
  color: #b48b0f !important;
  transform: scale(98%);
  background-color: unset;

}

.dropStyle{
  background-color: #173459ef !important;
}

.linkStyle {
  color: whitesmoke;
  font-weight: 550;
  font-size: 25px;
  transition: 0.5s all;
}

.linkDropStyle {
  color: whitesmoke;
  font-weight: 550;
  font-size: 25px;
  transition: 0.5s all;
}

.lang {
  width: 30px;
  font-size: 25px;
  margin-inline: 1px;
  font-weight: 550;
  transition: 0.5s all;
}

.btnNav {
  border: 0 !important;
}
.btnNav:focus {
  border: 1px solid whitesmoke !important;
  border-radius: 10% !important;
}

.lang:hover {
  color: #b48b0f !important;
  transform: scale(98%);
}

@media only screen and (max-width: 600px) {
  .navWid{
    width : 97%
  }
  .ulStyle {
    margin-right: 0px;
  }
 

  .logo {
    margin-left: 10px;
    width: 140px;
    height: 65px;
  }

  .lang {
    margin-inline: auto;
  }

}
@media only screen and (min-width: 601px) {
  .navWid{
    width : 90%
  }
  .ulStyle {
    margin-right: 35px;
  }
  .divLang {
    margin-inline: 27px;
  }

  .logo {
    width: 150px;
    height: 50px;
    margin-inline: 25px;
  }
}

@media only screen and (max-width: 990px) {

  .line {
    width: 95%;
    height: 2px;
    margin-inline: auto;
    background-color: #b48b0f;
  }


}

@media only screen and (min-width: 991px) {
  .line {
    width: 2px;
    height: 30px;
    background-color: #b48b0f;
  }
}
