@media only screen and (max-width: 600px) {
    .container {
        width: 100% !important;
        margin-inline: auto !important;
        /* margin-top: 150px !important; */
    }
    
.header {
    border-bottom: 4px solid var(--rating-color) ;
    border-left: 4px solid var(--rating-color) ;
    border-right: 4px solid var(--rating-color) ;
    width: 98%;
    padding-bottom: 8px;
    margin-inline: auto;
}

    .spaceBetween{
        margin-inline: 0px !important  ;
        margin-top: 20px;
    }

    .maged{
        margin-top: 5px;
    }
}


@media only screen and (min-width: 990px) and (max-width: 1090px) {
    .sizeProfile{
        font-size: 18px ;
    }
  }


@media only screen and (min-width: 1091px) and (max-width: 1200px) {
    .sizeProfile{
        font-size: 20px ;
    }
  }

  @media only screen and (min-width: 1201px) and (max-width: 1710px) {
    .sizeProfile{
        font-size: 25px ;
    }
  }

  @media only screen and (min-width: 1400px) and (max-width: 1710px) {
    .sizeProfile{
        font-size: 25px ;
    }
  }



@media only screen and (min-width: 601px) {
    .container {
        width: 100% !important;
        margin-inline: auto !important;
        /* margin-top: 130px !important; */
    }

    .header {
        border-bottom: 4px solid var(--rating-color) ;
        border-left: 4px solid var(--rating-color) ;
        border-right: 4px solid var(--rating-color) ;
        width: 33%;
        padding-bottom: 8px;
        margin-inline: auto;
    }
    

    .spaceBetween{
        padding-inline: 0px ;
      
    }

    .maged{
        margin-top: 80px;
    }
}

@media only screen and (min-width: 992px) {
    .container {
        width: 100% !important;
    }

    .spaceBetween{
        padding-inline: 60px ;
    }

    .maged{
        margin-top: 80px;
    }
}

.about p {
    line-height: 1.4 !important;
}
.picBorder {
    padding: 10px 0 0 10px;
    border-top: 5px solid var(--rating-color);
    border-left: 5px solid var(--rating-color);
}

.picAbout{
    width: 100%;
    
}


.hrStyle {
    border: 1px solid var(--rating-color) ;
    
}

