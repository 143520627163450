footer {
  border-top: 1px solid whitesmoke;
  background-image: url("../../Assets/cover/cover.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  color: var(--container-color) !important;
}

@media (max-width: 600px) {
  .container {
    width: 97%;
    margin-inline:  auto;
  }
  .paddingLink{
    padding-bottom: 10px;
  }

}

.container {
  width: 90%;
  margin-inline:  auto;
}

.container .linkStyle {
  color: whitesmoke !important ;
  transition: all 0.3s;

  font-weight: 550;
}

.container .linkStyle:hover {
  color: #b48b0f !important;
}

.socialIcons i {
  color: var(--text-color);
  transition: 0.5s all;
  margin-left: 8px;
}

.socialIcons i:hover {
  color: var(--bolder-color);
  transform: scale(1.2);
  color: var(--rating-color);
}


.ulStyle{
  list-style: none;
}